import { type ReactNode, useReducer } from 'react'
import { FocusOn } from 'react-focus-on'
import styled from 'styled-components'
import { rgba } from 'polished'
import { Image } from '@/elements/Image/NextImage'
import {
  NegativeIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  LogoMarkIcon,
} from '@shipt/design-system-icons'
import { NextLink } from '@/elements/Link'
import { Body1 } from '@/styles/typography'
import { routes } from '@shared/constants/routes'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import {
  urlBuilder,
  getUniversalCategories,
} from '@/services/UniversalCategories/utils'
import { spacing } from '@/theme/tokens'
import { Body, Headline } from '@shipt/design-system-typography'
import { Row } from '@shipt/design-system-layouts'
import { TransparentButton } from '@/elements/Buttons'
import { MAKE_MONEY_LINKS, MEMBERSHIP_POPOVER_LINKS } from '@/constants/layouts'
import { ButtonLink } from '@/elements/Link/Buttons'
import { trackElementClicked } from '@/analytics/element'

type Props = {
  isOpen: boolean
  closeMenu: () => void
}
type NavLink = { name: string; url: string }

export const MobileMenu = ({ isOpen, closeMenu }: Props) => {
  const categories = getUniversalCategories()

  if (useMediaQuery().screenLg && isOpen) {
    closeMenu()
  }

  const MEMBERSHIP_CTA_TEXT = 'Get a 14-day free trial'
  const MEMBERSHIP_CTA_ROUTE = `${routes.MEMBERSHIP_DASHBOARD.url}?student_email=true`

  const renderNavLinks = (links: NavLink[]) =>
    links.map(({ name, url }) => (
      <NextLink href={url} key={name}>
        <NavLinkTitle strong size="lg">
          {name}
        </NavLinkTitle>
      </NextLink>
    ))
  const renderMenuItemLinks = (links: NavLink[]) =>
    links.map(({ url, name }) => (
      <MenuItemLinkWrapper key={name}>
        <NextLink href={url}>
          <MenuLinkText color="plum">{name}</MenuLinkText>
        </NextLink>
      </MenuItemLinkWrapper>
    ))

  return (
    <FocusOn enabled={isOpen} autoFocus={true} returnFocus={true}>
      <Wrapper isOpen={isOpen}>
        <CloseIcon onClick={closeMenu} />
        <MembershipContentWrapper>
          <Row spacing="sm" align="center">
            <MembershipIcon />
            <Headline surface="inverse" size="md">
              Unlock Shipt membership
            </Headline>
          </Row>
          <Body size="md" surface="inverse" lineHeight="compact">
            14-day free trial with unlimited deliveries. Offer is subject to
            Terms & Conditions.
          </Body>
          <ButtonLink
            concept="accent"
            size="sm"
            href={MEMBERSHIP_CTA_ROUTE}
            onClick={() => {
              trackElementClicked({
                type: 'link',
                next_location: MEMBERSHIP_CTA_ROUTE,
                content: MEMBERSHIP_CTA_TEXT,
                display_sublocation: 'header_mobile_menu_membership_banner',
              })
            }}
          >
            {MEMBERSHIP_CTA_TEXT}
          </ButtonLink>
        </MembershipContentWrapper>
        {renderNavLinks([
          { name: 'Sign in', url: routes.LOGIN.url },
          { name: 'Sign up', url: routes.SIGN_UP.url },
        ])}
        <MenuItemContent title="Make money with us">
          {renderMenuItemLinks(MAKE_MONEY_LINKS)}
        </MenuItemContent>
        {renderNavLinks([
          { name: 'Services', url: routes.DELIVERY.url },
          { name: 'Stores', url: routes.STORES.url },
        ])}
        <MenuItemContent title="Categories">
          <MenuItemLinkWrapper>
            <NextLink href={routes.UNGATED_CATEGORY_HUB.url}>
              <MenuLinkText color="plum">View all categories</MenuLinkText>
            </NextLink>
          </MenuItemLinkWrapper>
          {categories.map((category) => (
            <MenuItemLinkWrapper key={category.id}>
              <CategoryIcon>
                <Image
                  src={category.image_url}
                  fallbackType="category"
                  alt={category.name}
                  width={28}
                  height={28}
                />
              </CategoryIcon>
              <NextLink href={urlBuilder(categories, category)}>
                <MenuLinkText color="plum">{category.name}</MenuLinkText>
              </NextLink>
            </MenuItemLinkWrapper>
          ))}
        </MenuItemContent>
        <MenuItemContent title="Memberships">
          {renderMenuItemLinks(MEMBERSHIP_POPOVER_LINKS)}
        </MenuItemContent>
      </Wrapper>
    </FocusOn>
  )
}

const MenuItemContent = ({
  title,
  children,
}: {
  title: 'Categories' | 'Memberships' | 'Make money with us'
  children: ReactNode
}) => {
  const [isOpen, toggleIsOpen] = useReducer((state) => !state, false)

  return (
    <ContentWrapper>
      <MenuItemButton onClick={toggleIsOpen}>
        <Body strong size="lg">
          {title}
        </Body>{' '}
        {isOpen ? <ChevronUpIcon size="sm" /> : <ChevronDownIcon size="sm" />}
      </MenuItemButton>
      {isOpen && children}
    </ContentWrapper>
  )
}

const Wrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  inset: 0;
  z-index: 2;
  padding: ${spacing('xxl', 40)};
  background-color: #fff;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  overflow-y: auto;
`

const CloseIcon = styled(NegativeIcon)`
  position: absolute;
  top: ${spacing('lg')};
  right: ${spacing('lg')};
  padding: ${spacing('md')};
`

const MembershipContentWrapper = styled.div`
  margin: 2rem 1rem 3rem 0;
  padding: 1rem;
  max-width: 268px;
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.plum};

  a {
    margin-top: 1rem;
  }
`

const NavLinkTitle = styled(Body)`
  display: block;
  color: ${({ theme }) => theme.plum};
  margin-bottom: ${spacing('xxl')};
`

const ContentWrapper = styled.div`
  margin-bottom: ${spacing('xxl')};
`

const MenuItemButton = styled(TransparentButton)`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    margin-left: ${spacing('sm')};
  }
`

const MenuItemLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: ${spacing(20, 0)};
  /* stylelint-disable-next-line color-function-notation -- this is a util from the library 'polished', not the CSS rgba function */
  border-bottom: 1px solid ${({ theme }) => rgba(theme.purple700, 0.1)};

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    margin-bottom: ${spacing('xxl')};
  }
`

const CategoryIcon = styled.div`
  width: 28px;
  margin-right: ${spacing('xl')};
`

const MenuLinkText = styled(Body1)`
  display: block;
`

const MembershipIcon = styled(LogoMarkIcon)`
  width: 1rem;
  height: 1rem;
`
