import { useState } from 'react'
import styled from 'styled-components'
import { Row } from '@shipt/design-system-layouts'
import { MenuIcon } from '@shipt/design-system-icons'
import { Body } from '@shipt/design-system-typography'
import { TransparentButton } from '@/elements/Buttons'
import { breakpoints } from '@/styles/media'
import { LayoutContainer } from '@/styles/layout'
import { HeaderLogoLink } from '@/components/Headers/HeaderLogoLink'
import { MobileMenu } from '@/components/Headers/UngatedHeader/MobileMenu'
import { useUserId } from '@/services/User/hooks'
import { ClientOnly } from '@/components/ClientOnly'
import { NextLink } from '@/elements/Link'
import { spacing } from '@/theme/tokens'
import { routes } from '@shared/constants/routes'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { HeaderUserLinks } from '@/components/Headers/HeaderUserLinks'
import { MAKE_MONEY_LINKS, MEMBERSHIP_POPOVER_LINKS } from '@/constants/layouts'
import dynamic from 'next/dynamic'

const CategoryPopover = dynamic(
  async () => (await import('./NavPopover/CategoryPopover')).CategoryPopover
)
const LinksPopover = dynamic(
  async () => (await import('./NavPopover/LinksPopover')).LinksPopover
)

type Props = { hideNavLinks?: boolean }

export const UngatedHeaderContent = ({ hideNavLinks }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const userId = useUserId()
  const { screenSm } = useMediaQuery()
  const linkSize = screenSm ? 'sm' : 'md'

  return (
    <LayoutContainer enableXLarge>
      <Nav>
        <Row align="center">
          <MenuButton
            onClick={() => setIsOpen(!isOpen)}
            aria-label="Hamburger Menu"
          >
            <MenuIcon />
          </MenuButton>
          <HeaderLogoLink />
          <ClientOnly>
            {!userId && !hideNavLinks && (
              <NavLinkWrapper spacing="xl">
                <NextLink href={routes.DELIVERY.url}>
                  <UngatedNavLink strong>Services</UngatedNavLink>
                </NextLink>
                <NextLink href={routes.STORES.url}>
                  <UngatedNavLink strong>Stores</UngatedNavLink>
                </NextLink>
                <CategoryPopover />
                <LinksPopover
                  title="Memberships"
                  links={MEMBERSHIP_POPOVER_LINKS}
                />
                <LinksPopover
                  title="Make money with us"
                  links={MAKE_MONEY_LINKS}
                />
              </NavLinkWrapper>
            )}
          </ClientOnly>
        </Row>
        <HeaderUserLinks linkSize={linkSize} />
      </Nav>
      <MobileMenu isOpen={isOpen} closeMenu={() => setIsOpen(false)} />
    </LayoutContainer>
  )
}

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  height: 4.5rem;
  padding: 0 1.25rem;

  @media ${breakpoints.medium} {
    padding: 0;
  }
`

const MenuButton = styled(TransparentButton)`
  padding: 0;
  margin-right: 1rem;

  @media ${breakpoints.medium} {
    margin-right: 1.5rem;
  }

  @media ${breakpoints.large} {
    display: none;
  }
`

const NavLinkWrapper = styled(Row)`
  display: none;

  @media ${breakpoints.large} {
    display: flex;
    align-items: center;
    margin-left: 1.5rem;
  }
`

const UngatedNavLink = styled(Body)`
  position: relative;
  color: ${({ theme }) => theme.plum};

  &:hover,
  &:focus {
    &::after {
      display: block;
      position: absolute;
      margin-top: ${spacing('xs')};
      left: calc(50% - 1rem);
      content: '';
      width: 2rem;
      height: 0.125rem;
      background-color: ${({ theme }) => theme.green};
    }
  }
`
